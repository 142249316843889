@font-face {
  font-family: 'Angler';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Angler.otf');
  src: url('./assets/fonts/Angler.otf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'appopaint';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/appopaint-Regular.otf');
  src: url('./assets/fonts/appopaint-Regular.otf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'BebasNeue';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/BebasNeue-Regular.ttf');
  src: url('./assets/fonts/BebasNeue-Regular.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Bensch Gothic';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Bensch Gothic.ttf');
  src: url('./assets/fonts/Bensch Gothic.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Candara';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Candara.ttf');
  src: url('./assets/fonts/Candara.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Ginchiest';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Ginchiest.ttf');
  src: url('./assets/fonts/Ginchiest.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Graphik.ttc');
  src: url('./assets/fonts/Graphik.ttc') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'JosefinSans';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/JosefinSans-Regular.ttf');
  src: url('./assets/fonts/JosefinSans-Regular.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Marker Attack';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Marker Attack.ttf');
  src: url('./assets/fonts/Marker Attack.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'PlayfairDisplay';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/PlayfairDisplay-Regular.ttf');
  src: url('./assets/fonts/PlayfairDisplay-Regular.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Roboto-Bold.ttf');
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'YesevaOne';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/YesevaOne-Regular.ttf');
  src: url('./assets/fonts/YesevaOne-Regular.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;  
  src: url('./assets/fonts/Graphik-Regular.ttf');
  src: url('./assets/fonts/Graphik-Regular.ttf') format('truetype'); /* Safari, Android, iOS */       
}

@font-face {
  font-family: 'Graphik';
  font-style: bold;  
  font-weight: 900;  
  src: url('./assets/fonts/Graphik-Semibold.ttf');
  src: url('./assets/fonts/Graphik-Semibold.ttf') format('truetype'); /* Safari, Android, iOS */       
}


body {
  min-height: 100vh;
  margin: 0;
  /* font-family: 'Lato', sans-serif; */
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262626;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 1);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;  
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

footer {
  border: 3px solid #d6d6d6;
  border-width: 3px 0px 0px 0px;
  padding-top: 5px;
  font-size: 12px;
}

footer .h5 {
  font-size: 12px;
}

.featureIcon {
  min-width: 100px;
}

.featureIcon img{
  /* margin: 0px 20px 5px 20px; */
  height: 100px;
  width: auto;
}

.breadcrumb-area {
  background-color: #f7f7f7;
  padding-bottom: 35px;
  padding-top: 35px;
}

.product-thumbnail img {
  width: 80px;  
  height: auto;  
}

.thumbnail {  
  text-align: center;
}
.thumbnail img {
  width: 100px;    
}

.order-success__header {
  text-align: center;
  margin: 30px 0px 30px;
}

.order-status-info {
  display: grid;
  text-align: center;
}

.card {
  background-color: transparent;  
}

.list-group {
  font-weight: 300;
}

.list-group-item {
  background-color: transparent;
  border: none  
}

 
.product-large-image-wrapper {
  overflow: hidden;
}

.product-small-image-wrapper {
  background-color: white;  
}

.galleryItemExpand {
  position: absolute;
  z-index: 99;
  top: 2%;
  left: 2%;
}

.single-image {
  text-align: center;
}

.btn-login-facebook {
  background-color: #4c69ba;
  color: white
}

.product__new-price {
  font-size: 30px;  
} 

.product__old-price {
  color: #b3b3b3;
  font-size: 16px;
  text-decoration: line-through;
}

.product__discount {
  font-size: 14px;
  margin-left: 10px;
}

.featureCard {
  padding: 0;
}

.variant-title {
  font-size: 16px;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 0px;
  
}

.stick {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 80px;  
}

.customToggleButton input[type="checkbox"] {
  position: absolute;
  clip: rect(0,0,0,0);
  margin-left: 10px;
}

.customToggleButton input[type="radio"] {
  position: absolute;
  clip: rect(0,0,0,0);
  margin-left: 10px;
}

.customToggleButton img {    
  max-width: 100%; 
  height: auto;
}


/* .customToggleButton .withBorderOnlyInActive {
  border: 4px solid transparent;      
} */

.customToggleButton .withBorder {
  color: gray;
  border: 1px solid gray;  
  padding: 0.25rem;
}

.customToggleButton .withColorPrimary {                              
  margin: 7px 0px;
}

.customToggleButton.nav-link {
  margin: 7px 0px;
  padding: 0px;
  color: black;
}

.customToggleButton.category {
  font-weight: bold;
}

.customToggleButton.subcategory {
  padding-left: 14px;
}

.padding-horizontal-off .list-group-item {
  padding: 0.5rem 0rem;
}

.filter-header .card-header {
  background-color: transparent;
  border-bottom: 2px solid black;
  padding: 0.25rem 1.25rem;
}

label.customToggleButton {
  margin-bottom: 0;
}

.product-card {
  max-width: 300px;
  font-size: 12px;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}

.product-card__prices {  
  font-size: 14px;
  font-weight: 900;
}

.content-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.content-center-column {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.content-inline-scroll {
  display: flex;
  height: 100%;
  overflow: scroll;
}

.cart-table {
  border-collapse:separate; 
  border-spacing: 0px 5px;
}

.cart-table__row, 
.cart-table__row td,
.cart__totals-footer td
{  
  border-bottom: none;
  border-top: none;
  padding: 5px 3px;  
}

.cart-table__row{
  background-color: #fafafa;
}

.cart-table__row tr:hover{
  background-color: #f0f0f0;
}

.cart__totals-header td, .cart__totals-header th, .border-none {
  border: none;
}

.cart__totals-header .aling-right, .cart__totals-footer .aling-right {
  text-align: right;
}

.cart__totals-footer{
  font-size: 1.25rem;
}

.table-shop-cart {    
  background-color: #fafafa;
}

.padding-off {
  padding: 0;
}

.padding-1 {
  padding: 5px;
}

.padding-off .col-md-12, .padding-off .col-md-6 {
  padding: 0;
}

.filter-container {
  font-size: 12px;
}

.title {  
  font-size: 28px;
  font-weight: 900;
  margin: 10px 0px;  
}

.title-mobile {  
  font-size: 28px;
  font-weight: 900;
  margin: 10px 0px;  
}

.section-title {
  font-weight: bold;
  margin: 0px 0px 10px 0px;
}

.article .card {
  border-radius: 10px;
}

.article .card-footer {
  margin: 0px 10px;
  background-color: transparent;
  border-top: none;
}

.img-article-list {
  /* max-height: 200px; */
  width: 100%;
  /* object-fit: contain; */
  object-position: top;
  border-radius: 10px;
}

.img-article-details {
  /* max-height: 400px; */
  width: 100%;
  /* object-fit: contain; */
  object-position: top;
  border-radius:0;
}

.login-modal .modal-content {
  border-radius: 0;
}

.login-modal .modal-header {
  padding: 0rem 0.1rem;
  border: none;
}

.login-modal .modal-body {
  padding: 0;
  border: none;
}

.login-modal .modal-footer {
  padding: 0px 0px 10px 0px;
  border: none;
}

.login-modal .modal-footer .btn-link {  
  color: gray !important;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
  transform: translateY(-50%);
}

.swiper-button-next-custom {
  position: absolute;  
  top: 50%;
  right: 10px;
  left: 'auto';
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}

.swiper-button-prev-custom {
  position: absolute;  
  top: 50%;
  left: 10px;
  right: 'auto';
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}

.swiper-container {
  margin: 10px 20px;
  position: relative;
}

.swiper-container .swiper-wrapper {
  display: flex;
  grid-auto-flow: column;
  justify-content: space-evenly;
}

.menuItem {
  margin: 0px 0px 10px 0px;
}

.card-text-styled {  
  margin: 0px;
  font-weight:300;
  line-height: normal;
}

.card-text-styled  p {  
  margin: 0px;
  font-weight:300;
  line-height: normal;
} 

.designer-sections  p {  
  margin: 0px;
} 

.block-empty__body {
  text-align: center;
  margin: 30px;
}

/* Bug Fix: Google Places autocomplete not working (in Bootstrap modal)*/
.pac-container {
  background-color: #FFF;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}

/* Bug Fix: Google Places autocomplete not working (in Bootstrap modal)*/


.order-success__actions {
  margin:20px;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

#headerWithNavBar {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px 0px;
}

.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.cart-table__product-name {
  color: black;
  font-size: 15px;
  font-weight: bold;
}

.cart-table__column--quantity {
  color: black;
  font-size: 14px;
  font-weight: bold;
  max-width: 94px;
}
.cart-table__column--product {  
  min-width: 94px;
}

.cart-table__options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
}

.cart-table__options li:first-child {  
  font-weight: 300;  
}

.custom-primary-button {
  width: 100%;
  height: 57px;
  color: black;
  font-size: 20px;
  font-weight: bold;
  border-radius: 0;
}

.full-screen {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.customized-swiper-container {
  margin: 0px;
}

.form-control {
  border-radius: 2;
  border: 1px solid #fafafa;
}

.fa-2 {
  font-size: 2em;
}

.nav-panel-mobile {
  padding: 8px 0px 0px 0px;
}

.absolute-left {
  position: absolute;
  left: 0;
}
.absolute-right {
  position: absolute;
  right: 0;
}

.button-container {
  margin: 5px;
}

.dropdown-item:active {
  background-color: transparent;
}

.circle {  
  border-radius: 50%;  
  position: relative; /* If you want text inside of it */  
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  padding: 1rem 2rem;
  border-radius: 0;
}

.btn-slim {
  padding: 0.5rem 1rem;
  margin: 0.5rem;  
}

.popover-custom {
  border: none;
  border-radius: 0;
  box-shadow:0 0 15px 0 rgba(0,0,0,.1);
}

.card-thumbnail .card{
  padding: 10px;
  margin: 10px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow:0 0 15px 0 rgba(0,0,0,.1);
}

.card-thumbnail .btn {
  min-width: 70%;  
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.card-thumbnail .btn:hover {
  background-color: #054F70 !important;
}

.bold {
  font-weight: bold;
}

.no-borders {
  border: none !important
}

a:hover {  
  text-decoration: none;
}

.btn-link:hover{  
  text-decoration: none;
}

.fill-available {
  width: -webkit-fill-available;
}

.nav-panel-search-icon {
  color: gray
}

.plan-card .prices {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.plan-card .term {
  text-align: center;  
  font-weight: bold;
}

.overlay-container {
  z-index: 9999;
  background-color: #FFF;
  border: 1px solid #a0a0a0 !important;
  max-width: 50%;
  min-width: 30%;
}

.category-title {
  font-size: 12px;
}

.card-portrait {
  display: flex;
  flex:1;
  overflow: hidden;
  height: 192px;
}

.contacts-provider-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
}
.contacts-provider-styles {
  position: relative;  
  left: -50%;
  background-color: white;
  border: 1px solid gray;
  border-radius: 50px;
}

.provider-profile .carousel-item {
  max-height: 400px;
}

.brand-icon-movil {
  position: absolute;
  top: 20px;
  left: 40%;
}

.widget-icon-movil {
  position: absolute;
  top: 13px;
  right: 0;
  z-index: 2000;
}

.nav-panel-icon-movil {
  padding: 2px 5px;
}


.login-dropdown {
  min-width: 400px;
  padding: 0;
}

.section-tabs {
  justify-content: center;
}

.container-section-tabs {
  width: 100%;
}

.floating-section {
  position: fixed;
  right: 0;
  bottom: 50%;  
  transform: translateY(50%);
  z-index: 1030;
  box-shadow: -5px 5px 10px rgba(0,0,0,.5);
}

.floating-section-ajust {
  position: relative;  
  bottom: -50%;
}

.menuItem-icon {
  width: 40px;
  height: 40px;
  margin: 2px;
}

.section-menu-item-icon {
  width: 30px;
  margin: 5px;
}
div.form-group.border-red {
  padding: 10px;
}

input.border-red,
div.form-group.border-red {
  border: 1px solid red;
  border-radius: 0px;
}

input.border-red.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: red;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ff000021;
}

input.kw-btn{
  display: block;
  padding-bottom: 20px;
  margin-left: auto;
}

input.kw-input-subscribe:focus{
  color: #495057;
  background-color: #fff;
  border-color: red;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ff000021;
}

.kw-footer {
  padding-top: 5px !important;
}

.kw-gradient-background {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c1c1c1+0,f5f5f5+100 */
  background: #c1c1c1; /* Old browsers */
  background: -moz-linear-gradient(top,  #c1c1c1 0%, #f5f5f5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #c1c1c1 0%,#f5f5f5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #c1c1c1 0%,#f5f5f5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c1c1c1', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */

}