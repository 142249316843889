.container {
    width: 100%;
    /* min-height: 100vh; */
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    max-width: 1080px;
    /* border: 1px solid rgba(0,0,0,.15); */
    padding: 0 16px;
    margin-top: calc(86px + 32px);
}

.figure {
    /* background-color: yellow; */
    grid-column-start: 2;
    grid-column-end: -1;
}
.group {
    /* display: inline-block; */
    flex-grow: 1;
    /* padding: 0 32px; */
}
.Header{
    background-color: #3F3F3F !important;
    margin-bottom:10px !important
}

.backtitle{
    color:white;
}
.backtitlemovil{
    color:white;
}

.footerP{
    color:white;
    font-weight:bold;
}


.imgLogo{
    min-width: 10vw;
    width: 83px;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.titles{
    color:#454545;
    font-weight: 600;
    text-align: center;
    font-size: 48px;
    /* padding: 0 16px; */
    /* line-height: 4vw; */
}
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}
.subtitle {
    font-size: 36px;
}
.inform {
    display: flex;
    justify-content: center;
}

@media (min-width:800px){
    .backtitlemovil{
        display:none;
    }
}

@media (max-width:800px){
    .backtitle{
        display:none;
    }
}


.footerKenworth{
    position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #efefef;
  text-align: center;
    background-color: #DC2D34 !important;  
}

.inputDatoContacto{
    border: 1px solid red;
}

@media screen and (max-width: 752px) {
    .container {
        margin-top: calc(150px + 32px);
    }
}


@media screen and (max-width: 416px) {
    .titles {
        width: 100%;
        font-size: 30px;
        text-align: left;
        margin-top: calc(150px + 32px);
    }
}

@media screen and (max-width: 645px) {
    .figure {
        grid-column-start: 1;
    }
}