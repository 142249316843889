/*
// .input-number
*/

.input-number {
    display: block;
    width: 100%;
    position: relative;
}
.input-number__input {
    -moz-appearance: textfield;
    display: block;
    width: 100%;
    min-width: 88px;
    padding: 0 24px 1px;
    text-align: center;
    font-weight: bold;
    border: 1px solid black; 
    border-radius: 0;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}
.input-number__sub,
.input-number__add {
    position: absolute;
    height: 100%;
    width: 24px;
    top: 0;
    cursor: pointer;
    user-select: none;
    opacity: .8;
    transition: opacity .18s;

    &:before,
    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: currentColor;
    }

    &:before {
        width: 8px;
        height: 2px;
    }

    &:hover {
        opacity: 1;
    }
}
.input-number__sub {
    left: 1px;  
    border-right: 1px solid #fafafa; 
    border-radius: 0;      
}
.input-number__add {
    right: 1px;    
    border-left: 1px solid #fafafa; 
    border-radius: 0;

    &:after {
        width: 2px;
        height: 8px;
    }
}
