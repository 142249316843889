.icon-negocios-oficiales {
    width: 20px; 
    height: 20px;
    margin-right: 3px;
}

.negocio_oficial_perfil_producto {
    max-width:80px;
    height:'auto';
}

.icons-contat {
    width: 40px;
    height: 40px;
    margin: 10px;
    color: #05283D;    
}

.profile-product-name {
    font-size: 22px;
}

.galleryItemFavoritos {
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 0px;
  }