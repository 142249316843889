.carousel__card__kw {
    transition: all ease 0.3s;
    border-radius: 7px;
    padding: 10px 25px;
    z-index: 1;
    max-height: 323px;
}

.carousel__container {
    height: 31rem;
}

.carousel__container__item {
    height: 100% !important;
    padding-bottom: 1.2rem;
}

.carousel__image {
  width: 9rem;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: all ease 0.3s;
}

.animate-image {
    transform: translate(-50%, -91px);
    opacity: 1;
}

.slick-list, .slick-track, .slick-slider {
    height: 100% !important;
}

.slick-slide > div {
    height: 100%!important;
}

.slick-arrow::before {
    color: black !important;
}
