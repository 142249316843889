/*
// .checkout
*/


.checkout {}
.checkout__totals {
    font-size: 15px;
    line-height: 19px;
    width: 100%;
    margin-bottom: 40px;

    th, td {
        padding: 4px 0;
    }

    th {
        font-weight: 700;
    }

    tr > :first-child {
        padding-right: 20px;        
    }

    tr > :last-child {
        text-align: right;        
    }
}
.checkout__totals-header {
    tr:last-child > * {
        padding-bottom: 10px;
    }
}
.checkout__totals-products {
    tr:first-child > * {
        padding-top: 20px;
        border-top: 1px solid #ebebeb;
    }
    tr:last-child > * {
        padding-bottom: 20px;
        border-bottom: 1px solid #ebebeb;
    }
}
.checkout__totals-subtotals {
    tr:first-child > * {
        padding-top: 20px;
    }
    tr:last-child > * {
        padding-bottom: 20px;
        border-bottom: 1px solid #ebebeb;
    }
}
.checkout__totals-footer {
    font-size: 24px;

    th {
        font-weight: 500;
    }

    tr:first-child > * {
        padding-top: 20px;
    }
}




.dropcart__totals {
    border-top: 1px solid #ebebeb;
    line-height: 24px;
    padding: 16px 20px 0;

    td {
        text-align: right;        
    }

    table {
        width: 100%;
    }
}
